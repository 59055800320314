/* Code information
   ch stands for costumer hive
END */

* {
	font-family: 'Montserrat', sans-serif;
}

.ch-header * a {
	text-decoration: none;
	color: inherit;
}
.ch-header {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	height: 3.75rem;
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
	background-color: white;
	z-index: 1000;
	box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6);
	padding: 0 2rem;
}

.ch-header-nav {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 3.5rem;
	margin: auto;
	width: 100%;
	max-width: 75rem;
}

.ch-header-nav-links {
	display: flex;
	width: 70%;
	justify-content: flex-end;
	align-items: center;
}

.ch-header-nav-links > .ch-header-link {
	margin-left: 1.875rem;
}
.ch-grey-text {
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	text-decoration: none;
	color: var(--trip-text-gray);
}
th.ch-table-cell-header {
	cursor: pointer;
}
th.ch-table-cell-header svg {
	font-size: 0.9rem;
}
.ch-header-nav-links > .ch-header-link.ch-selected,
.ch-header-nav-links > .ch-header-link:hover {
	border-bottom: var(--pi-labs-blue) solid 1.5px;
}

.ch-content-filler {
	display: flex;
	width: 100%;
	margin: auto;
	padding-top: 3.75rem;
	max-width: 75rem;
	height: fit-content;
}

/*CUSTOMER MANAGEMENT*/

.ch-customer-table {
	margin-top: 4rem;
	margin-bottom: 4rem;
	width: 100%;
}
.ch-table-search-input {
	border: none;
	border-bottom: solid #aaa 2px;
	padding-left: 25px;
}
.ch-table-search-input:focus {
	border: none;
	outline: none;
	border-bottom: solid #555 2px;
}

.ch-header-logo {
	margin-top: 5%;
}

.ch-total-customer-variant {
	font-size: 0.9rem;
	color: black;
}

button.MuiButton-textPrimary:hover {
	background-color: rgba(0, 186, 255, 0.2);
}
button.MuiButton-textSecondary:hover {
	background-color: rgba(245, 0, 87, 0.2);
}

div > button.ch-create-btn > span > span {
	margin-left: 3px !important;
}
.ch-hide-content-beneath {
	position: sticky;
	top: 56px;
	left: 0;
	width: calc(100% - 20px);
	height: 66px;
	background-color: white;
	z-index: 1;
}

table {
	border-collapse: separate !important;
}

tbody tr:first-child td {
	position: sticky;
	top: 56px;
	z-index: 1;
	background: white;
}

@media screen and (max-width: 75rem) {
	.ch-content-filler {
		padding: 0 2rem;
	}
}
