:root {
	--pi-labs: rgb(52, 86, 93);
	--pi-labs-blue: rgb(0, 186, 255);
	--pi-labs-dark-blue: #003855;
	--pi-labs-shadow: rgba(52, 86, 93, 0.5);
	--pi-labs-grey: rgb(104, 137, 142);
	--pi-labs-grey-shadow: rgba(104, 137, 142, 0.5);
	--pixida: rgb(180, 10, 25);
	--pixida-shadow: rgba(180, 10, 25, 0.5);
	--font-family-sans-serif: Verdana !important;
	--trip-text-gray: #707070;
	--trip-border-gray: #90979b;
	--trip-background-gray: #dddddd;
	--trip-list-header-background: #61696e;
	--pilabs-blue: #00baff;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	padding: 0;
	line-height: 1.5rem;
	font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root,
.container {
	height: 100%;
}

/* LEGAL PAGES - START */
.sect1 h2 {
	font-size: 1.125rem;
}

.legal-page h2 {
	font-size: 1.5rem;
}

/* LEGAL PAGES - END */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Remove auto-fill background-color */
input:-webkit-autofill {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: white !important;
}
